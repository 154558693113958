import { Box } from 'components/Box';
import { InnerCell } from './InnerCell.styled';
import type { CookieData, CookieTableRow, cookieKey } from './types';

const cookieRowInfo: { id: cookieKey; title: string }[] = [
  {
    id: 'cookieName',
    title: 'Cookie Name',
  },
  {
    id: 'creator',
    title: 'Creator',
  },
  {
    id: 'purpose',
    title: 'Purpose',
  },
  {
    id: 'expiry',
    title: 'Expiry',
  },
];

export const buildMobileCookieRows = (data: CookieData[]): CookieTableRow[] =>
  data.reduce((acc, cookieData, i) => {
    const backgroundColor = i % 2 === 0 ? 'cream' : 'white';

    cookieRowInfo.forEach(row => {
      acc.push({
        id: `${cookieData.cookieName}-${cookieData.creator}-${row.id}`,
        cells: [
          <Box paddingLeft={2} key="title">
            <strong>{row.title}</strong>
          </Box>,
          <InnerCell key={row.id}>{cookieData[row.id]}</InnerCell>,
        ],
        backgroundColor,
      });
    });

    return acc;
  }, [] as CookieTableRow[]);
