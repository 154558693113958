import { FlexibleLinkComponent } from 'components/Links';
import type { CookieData } from './types';

export const performanceCookieRowData: CookieData[] = [
  {
    cookieName: '_ga',
    creator: 'Google Analytics',
    purpose:
      'This cookie is associated with Google Analytics, and is used to distinguish unique users by assigning a randomly generated number as a client identifier.',
    expiry: '2 years',
  },
  {
    cookieName: '_ga',
    creator: 'Sharesight',
    purpose:
      'This cookie is associated with Google Analytics, and is used to distinguish unique users by assigning a randomly generated number as a client identifier.',
    expiry: '2 years',
  },
  {
    cookieName: '_ga_RW4RQLNJDN',
    creator: 'Sharesight',
    purpose:
      'This Google Analytics cookie is used to collect anonymous information about how visitors use our website (e.g. which pages were viewed). We use this information to further improve and develop our website.',
    expiry: '2 years',
  },
  {
    cookieName: 'mp_4b8775477c9ef1439bbd6bbdcd10e781_mixpanel',
    creator: 'Sharesight',
    purpose: (
      <>
        This Mixpanel cookie is used to collect standard internet log and visitor behavior data in
        order to analyse visitor traffic. You can find more information about Mixpanel’s privacy
        policy here:{' '}
        <FlexibleLinkComponent to="https://mixpanel.com/privacy/">
          https://mixpanel.com/privacy/
        </FlexibleLinkComponent>
        .
      </>
    ),
    expiry: '1 year',
  },
  {
    cookieName: '_vis_opt_exp_{EXPERIMENT_ID}_combi',
    creator: 'Sharesight',
    purpose:
      'This Visual Website Optimizer cookie is generated when you reach a particular A/B test combination. This cookie ensures that you see the same variation that you saw on your previous visit to the page. Sharesight uses VWO to run A/B tests in order to further improve the website',
    expiry: '100 days',
  },
  {
    cookieName: '_vis_opt_exp_{EXPERIMENT_ID}_goal_GOAL_ID',
    creator: 'Sharesight',
    purpose: 'This Visual Website Optimizer cookie is generated when an A/B test goal is created.',
    expiry: '100 days',
  },
  {
    cookieName: '_vis_opt_s',
    creator: 'Sharesight',
    purpose:
      'This cookie is used by Visual Website Optimizer to store unique website visits. Sharesight uses VWO to run A/B tests in order to further improve the website.',
    expiry: '100 days',
  },
  {
    cookieName: '_vis_opt_test_cookie',
    creator: 'Sharesight',
    purpose:
      'This cookie is used by Visual Website Optimizer to read if cookies can be placed on the visitor’s device.',
    expiry: 'After browser session ends',
  },
  {
    cookieName: '_vwo_ds',
    creator: 'Sharesight',
    purpose:
      'This Visual Website Optimizer cookie stores persistent visitor-level data for reporting insights. It collects information about how visitors interact with the website pages.',
    expiry: '100 days',
  },
  {
    cookieName: '_vwo_sn',
    creator: 'Sharesight',
    purpose:
      'This cookie is used by Visual Website Optimizer to store session-level information. It collects data such as average time spent on the website and what pages have been viewed.',
    expiry: '30 minutes',
  },
  {
    cookieName: '_vwo_uuid',
    creator: 'Sharesight',
    purpose:
      'This Visual Website Optimizer cookie generates a unique ID for every visitor and is used for report segmentation.',
    expiry: '1 year',
  },
  {
    cookieName: '_dd_s',
    creator: 'Sharesight',
    purpose:
      'This Datadog cookie is used to group all events generated from a unique user session across multiple website pages. It contains the current session ID, whether the session is excluded due to sampling, and the expiration date of the session.',
    expiry: '4 hours',
  },
  {
    cookieName: 'utm_parameters',
    creator: 'Sharesight',
    purpose:
      'This Sharesight-owned cookie is used to store UTM parameter information such as campaign source and name for marketing and reporting purposes.',
    expiry: '1 month',
  },
];
