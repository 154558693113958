import type { CookieData } from './types';

export const functionalityCookieRowData: CookieData[] = [
  {
    cookieName: 'exit-intent-blog',
    creator: 'Sharesight',
    purpose:
      'This cookie remembers if you’ve previously closed the sign up modal on the website’s blog pages so as to not show it again.',
    expiry: '1 month',
  },
  {
    cookieName: 'sharesight_country',
    creator: 'Sharesight',
    purpose:
      'This Sharesight-owned cookie is used to render region-specific content if a user chooses to manually change what country they are from on the website.',
    expiry: 'After browser session ends',
  },
];
