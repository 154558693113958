import styled from 'styled-components';

export const InnerCell = styled.div`
  inline-size: 350px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    inline-size: 250px;
    overflow-wrap: anywhere;
  }
`;
