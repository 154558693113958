import { useMemo } from 'react';
import { cookiePolicyContent, cookiePolicyHeaders } from 'page-data/cookie-policy';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';

import { Container, HeroContainer, Section } from 'components/Container';
import { LegalPageFooter } from 'components/LegalPageFooter';
import { List } from 'components/List';

import { stringToAnchorId } from 'helpers/stringToAnchorId';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const lastUpdatedDate: Date = new Date('2024-01-07');

const CookiePolicy: FC<LocalizedPageProps> = () => {
  const rendercookiePolicyContent = useMemo(
    () =>
      cookiePolicyContent.map(({ heading, content }) => (
        <Container key={heading}>
          <Section>
            <h2 id={stringToAnchorId(heading)}>{heading}</h2>
            {content}
          </Section>
        </Container>
      )),
    []
  );

  return (
    <Layout>
      <Seo />
      {/* Header section */}
      <HeroContainer background="cream" skipColoration id="header">
        <Section>
          <h1>Sharesight Cookie Policy</h1>
          <br />
          <List noIndent itemGap={0} items={cookiePolicyHeaders} />
        </Section>
      </HeroContainer>

      {rendercookiePolicyContent}

      {/* To the top button */}
      <LegalPageFooter elementId="header" date={lastUpdatedDate} />
    </Layout>
  );
};

export default CookiePolicy;
