import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { Table } from 'components/Table';
import { buildCookieRows } from './buildCookieRows';
import { essentialCookieRowData } from './essentialCookieRowData';
import { functionalityCookieRowData } from './functionalityCookieRowData';
import { performanceCookieRowData } from './performanceCookieRowData';
import { advertisingCookieRowData } from './advertisingCookieRowData';
import { cookieColumns, mobileCookieColumns } from './columns';

import { buildMobileCookieRows } from './buildMobileCookieRows';
import type { FC } from 'react';

const cookieRowsData = {
  essential: essentialCookieRowData,
  functionality: functionalityCookieRowData,
  performance: performanceCookieRowData,
  advertising: advertisingCookieRowData,
};

export const CookieTable: FC<{
  cookieType: 'essential' | 'functionality' | 'performance' | 'advertising';
}> = ({ cookieType }) => {
  const theme = useTheme();
  const isMobile = !!useMediaQuery(theme.breakpoints.down('sm'));

  const rows = buildCookieRows(cookieRowsData[cookieType]);
  const mobileCookieRows = buildMobileCookieRows(cookieRowsData[cookieType]);

  if (isMobile) return <Table columns={mobileCookieColumns} rows={mobileCookieRows} />;

  return <Table columns={cookieColumns} rows={rows} />;
};
