import type { TableColumn } from '../types';

export const cookieColumns: TableColumn[] = [
  { id: 'Name', title: <h5>Cookie name</h5> },
  { id: 'Creator', title: <h5>Creator</h5> },
  { id: 'Purpose', title: <h5>Purpose</h5> },
  { id: 'Expiry', title: <h5>Expiry</h5> },
];

export const mobileCookieColumns: TableColumn[] = [{ id: 'Title' }, { id: 'Value' }];
