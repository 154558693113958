import type { CookieData } from './types';

export const essentialCookieRowData: CookieData[] = [
  {
    cookieName: 'campaign_code',
    creator: 'Sharesight',
    purpose:
      'This Sharesight-owned cookie stores an optional promotional code for pricing discounts inline with campaign offers that can be used by paying customers.',
    expiry: '1 month',
  },
  {
    cookieName: 'cookie-consent',
    creator: 'Sharesight',
    purpose:
      'This cookie is used by Sharesight to check whether a website visitor has saved their cookie consent preferences.',
    expiry: '12 months',
  },
  {
    cookieName: 'intercom-session-tv6jsyee',
    creator: 'Sharesight',
    purpose:
      'This Intercom session cookie is used as an identifier for each unique browser session. It allows users to access their Intercom conversations on logged-out pages for up to 1 week.',
    expiry: '1 week',
  },
  {
    cookieName: 'intercom-device-idtv6jsyee',
    creator: 'Sharesight',
    purpose:
      'This Intercom cookie is used to identify the device interacting with the Intercom messenger app for security purposes.',
    expiry: '9 months',
  },
  {
    cookieName: 'intercom-id-tv6jsyee',
    creator: 'Sharesight',
    purpose:
      "This cookie is used as an anonymous visitor identifier by Intercom. It allows visitors to see any conversations they've had on the Intercom messenger app.",
    expiry: '9 months',
  },
];
