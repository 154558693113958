import type { CookieData } from './types';

export const advertisingCookieRowData: CookieData[] = [
  {
    cookieName: 'AnalyticsSyncHistory',
    creator: 'LinkedIn',
    purpose: 'This LinkedIn cookie is used to store and track visits to Sharesight’s website.',
    expiry: '1 month',
  },
  {
    cookieName: 'IDSYNC',
    creator: 'Yahoo',
    purpose:
      'This Yahoo remarketing cookie is used for advertising purposes. It checks if a visitor has been to our website before, which allows us (or a third party) to display an appropriate advertisement on another website.',
    expiry: '1 year',
  },
  {
    cookieName: '1P_JAR',
    creator: 'Google',
    purpose:
      'This third-party cookie is used by Google Ads Optimization for marketing and retargeting purposes.',
    expiry: '1 month',
  },
  {
    cookieName: 'A3',
    creator: 'Yahoo',
    purpose:
      'This Yahoo cookie stores a unique identifier which is used to display and measure personalised advertising content.',
    expiry: '1 year',
  },
  {
    cookieName: 'AEC',
    creator: 'Google',
    purpose:
      'Google’s AEC cookie ensures that requests within a browsing session are made by the user, and not by other sites. This cookie prevents malicious sites from acting on behalf of a user without that user’s knowledge.',
    expiry: '6 months',
  },
  {
    cookieName: 'APISID',
    creator: 'Google',
    purpose:
      'This Google ads cookie personalises advertisements on third-party websites based on recent Google searches and interactions.',
    expiry: '6 months to 10 years',
  },
  {
    cookieName: 'HSID',
    creator: 'Google',
    purpose:
      'This is a security cookie to confirm visitor authenticity, prevent fraudulent use of login data and protect visitor data from unauthorized access.',
    expiry: '2 years',
  },
  {
    cookieName: 'MR',
    creator: 'Microsoft',
    purpose:
      'This is a Microsoft cookie which is used to measure website interactions for ad analytics.',
    expiry: '6 months',
  },
  {
    cookieName: 'MUID',
    creator: 'Microsoft',
    purpose:
      'This cookie is used by Microsoft as a unique user identifier, allowing user tracking for advertising purposes.',
    expiry: '1 year',
  },
  {
    cookieName: 'MSPTC',
    creator: 'Microsoft',
    purpose:
      'This is a Microsoft Bing cookie used to serve relevant advertisements to visitors on other websites.',
    expiry: '1 year',
  },
  {
    cookieName: 'NID',
    creator: 'Google',
    purpose:
      'The “NID” cookie is used by Google to store user preferences for advertising purposes.',
    expiry: '6 months to 10 years',
  },
  {
    cookieName: 'SAPISID',
    creator: 'Google',
    purpose:
      'This cookie is used by Google to display personalised advertisements on Google sites such as YouTube, based on recent searches and previous interactions.',
    expiry: '2 years',
  },
  {
    cookieName: 'SID',
    creator: 'Google',
    purpose:
      'This is a security cookie to confirm visitor authenticity, prevent fraudulent use of login data and protect visitor data from unauthorized access.',
    expiry: '2 years',
  },
  {
    cookieName: 'SIDCC',
    creator: 'Google',
    purpose:
      'This is a security cookie to confirm visitor authenticity, prevent fraudulent use of login data and protect visitor data from unauthorized access.',
    expiry: '2 years',
  },
  {
    cookieName: 'SSID',
    creator: 'Google',
    purpose:
      'This cookie is used by Google to display personalised advertisements on Google sites such as YouTube, based on recent searches and previous interactions.',
    expiry: '6 months to 10 years',
  },
  {
    cookieName: '__Secure-1PAPISID',
    creator: 'Google',
    purpose:
      'This Google cookie builds a profile of website visitor interests to show relevant and personalised ads through retargeting.',
    expiry: '2 years',
  },
  {
    cookieName: '__Secure-1PSID',
    creator: 'Google',
    purpose:
      'This Google cookie builds a profile of website visitor interests to show relevant and personalised ads through retargeting.',
    expiry: '2 years',
  },
  {
    cookieName: '__Secure-1PSIDCC',
    creator: 'Google',
    purpose:
      'This Google cookie builds a profile of website visitor interests to show relevant and personalised ads through retargeting.',
    expiry: '2 years',
  },
  {
    cookieName: '__Secure-3PAPISID',
    creator: 'Google',
    purpose:
      'This Google cookie builds a profile of website visitor interests to show relevant and personalised ads through retargeting.',
    expiry: '2 years',
  },
  {
    cookieName: '__Secure-3PSID',
    creator: 'Google',
    purpose:
      'This Google cookie builds a profile of website visitor interests to show relevant and personalised ads through retargeting.',
    expiry: '2 years',
  },
  {
    cookieName: '__Secure-3PSIDCC',
    creator: 'Google',
    purpose:
      'This Google cookie builds a profile of website visitor interests to show relevant and personalised ads through retargeting.',
    expiry: '2 years',
  },
  {
    cookieName: '_fbp',
    creator: 'Sharesight',
    purpose:
      'This third-party Facebook cookie is used to store and track visits across the website for advertising and analytics purposes.',
    expiry: '3 months',
  },
  {
    cookieName: '_gcl_au',
    creator: 'Sharesight',
    purpose:
      'This is a Google AdSense cookie used for experimenting with advertisement efficiency across websites using their services.',
    expiry: '3 months',
  },
  {
    cookieName: '_rdt_uuid',
    creator: 'Sharesight',
    purpose:
      'This cookie is used to attribute conversions back to Reddit ads. It helps build a profile of your interests to show you relevant ads.',
    expiry: '3 months',
  },
  {
    cookieName: '_uetsid',
    creator: 'Sharesight',
    purpose:
      'This Microsoft Bing cookie collects data on visitor behavior from multiple websites in order to present more relevant advertisements.',
    expiry: '1 day',
  },
  {
    cookieName: '_uetvid',
    creator: 'Sharesight',
    purpose:
      'This third-party cookie is used by Bing Ads to store and track visits across websites for advertising purposes.',
    expiry: '13 months',
  },
  {
    cookieName: 'guest_id',
    creator: 'Twitter',
    purpose: 'This Twitter cookie is for authentication to identify and track the website visitor.',
    expiry: '13 months',
  },
  {
    cookieName: 'guest_id_ads',
    creator: 'Twitter',
    purpose: 'This cookie is for Twitter advertising.',
    expiry: '13 months',
  },
  {
    cookieName: 'guest_id_marketing',
    creator: 'Twitter',
    purpose: 'This cookie is for Twitter advertising.',
    expiry: '13 months',
  },
  {
    cookieName: 'muc_ads',
    creator: 'Twitter',
    purpose: 'This cookie is for Twitter advertising.',
    expiry: '13 months',
  },
  {
    cookieName: 'personalization_id',
    creator: 'Twitter',
    purpose:
      'This cookie is set by Twitter for integration and sharing capabilities on social media.',
    expiry: '13 months',
  },
  {
    cookieName: 'sa-user-id',
    creator: 'StackAdapt',
    purpose:
      'StackAdapt sets this cookie as a random identifier for user identification to display relevant advertisements.',
    expiry: '1 year',
  },
  {
    cookieName: 'sa-user-id-v2',
    creator: 'StackAdapt',
    purpose:
      'StackAdapt sets this cookie as a random identifier for user identification to display relevant advertisements.',
    expiry: '1 year',
  },
  {
    cookieName: 'sa-user-id-v3',
    creator: 'StackAdapt',
    purpose:
      'This third-party advertising cookie tracks your website activity - such as the pages you’ve visited and the locations you’ve viewed - in order to suggest personalised content and ads on external websites.',
    expiry: '1 year',
  },
  {
    cookieName: 'session',
    creator: 'Dianomi',
    purpose: 'This Dianomi cookie is used to store a dmp session id for marketing purposes.',
    expiry: '180 days',
  },
  {
    cookieName: 'UserMatchHistory',
    creator: 'LinkedIn',
    purpose: 'This third-party LinkedIn cookie is used for ads ID syncing.',
    expiry: '30 days',
  },
  {
    cookieName: 'bcookie',
    creator: 'LinkedIn',
    purpose:
      'This LinkedIn browser Identifier cookie is used to uniquely identify devices accessing LinkedIn to detect abuse on the platform.',
    expiry: '1 year',
  },
  {
    cookieName: 'fr',
    creator: 'Facebook',
    purpose: 'This cookie is used for ads targeting on Facebook.',
    expiry: '3 months',
  },
  {
    cookieName: 'lang',
    creator: 'Google Analytics',
    purpose: "This LinkedIn cookie is used to remember a user's language settings.",
    expiry: 'After browser session ends',
  },
  {
    cookieName: 'li_sugr',
    creator: 'LinkedIn',
    purpose:
      "This third-party cookie is used to make a probabilistic match of a user's identity outside of LinkedIn’s designated countries.",
    expiry: '90 days',
  },
  {
    cookieName: 'lidc',
    creator: 'LinkedIn',
    purpose: 'This LinkedIn cookie is used for facilitating data center selection.',
    expiry: '1 day',
  },
  {
    cookieName: 'AMCVS_14215E3D5995C57C0A495C55%40AdobeOrg',
    creator: 'LinkedIn',
    purpose: 'This is a LinkedIn cookie used to gather analytics for advertising purposes.',
    expiry: '2 years',
  },
  {
    cookieName: 'AMCV_14215E3D5995C57C0A495C55%40AdobeOrg',
    creator: 'LinkedIn',
    purpose: 'This is a LinkedIn cookie used to gather analytics for advertising purposes.',
    expiry: '2 years',
  },
  {
    cookieName: 'csrf_token',
    creator: 'Reddit',
    purpose: 'This Reddit cookie is used for session authentication.',
    expiry: '1 year',
  },
  {
    cookieName: 'csv',
    creator: 'Reddit',
    purpose: 'This third-party cookie is used for Reddit ads.',
    expiry: '1 year',
  },
  {
    cookieName: 'edgebucket',
    creator: 'Reddit',
    purpose: 'This third-party cookie is used for Reddit ads.',
    expiry: '1 year',
  },
  {
    cookieName: 'loid',
    creator: 'Reddit',
    purpose: 'This third-party cookie is used for Reddit ads.',
    expiry: '1 year',
  },
  {
    cookieName: 'rdt',
    creator: 'Reddit',
    purpose: 'This third-party cookie is used for Reddit ads.',
    expiry: '1 year',
  },
  {
    cookieName: 'session_tracker',
    creator: 'Reddit',
    purpose: 'This third-party cookie is used for Reddit ads.',
    expiry: '1 year',
  },
  {
    cookieName: 'token_v2',
    creator: 'Reddit',
    purpose: 'This third-party cookie is used for Reddit ads.',
    expiry: '1 year',
  },
  {
    cookieName: '__spdt',
    creator: 'Sharesight',
    purpose:
      'This cookie is used to analyse user interaction on site that audio listeners take after hearing or seeing an ad on Spotify.',
    expiry: '13 months',
  },
];
