import { FlexibleLinkComponent, GatsbyLink } from 'components/Links';

import { stringToAnchorId } from 'helpers/stringToAnchorId';

import { CookieTable } from 'components/Table/CookieTable/CookieTable';
import { Table, type TableColumn } from 'components/Table';
import type { ReactNode } from 'react';
import type { Clause } from 'components/List';

interface CookiePolicyContent {
  heading: string;
  content: ReactNode;
}

const getSectionLink = (id: string) => `/cookie-policy/#${stringToAnchorId(id)}`;
const getLinkTitle = (id: string) => `${id} | Cookie Policy | Sharesight`;

const headerLink = (text: string): Clause => ({
  id: text,
  content: (
    <GatsbyLink textColor="black" to={getSectionLink(text)} title={getLinkTitle(text)}>
      {text}
    </GatsbyLink>
  ),
});

export const browserColumns: TableColumn[] = [
  { id: 'Browser', title: <h5>Browser</h5> },
  { id: 'Link', title: <h5>Link</h5> },
];

const browserCells = [
  {
    id: 'Chrome',
    cells: [
      'Chrome',
      <FlexibleLinkComponent key="chrome" to="https://support.google.com/chrome/answer/95647?hl=en">
        https://support.google.com/chrome/answer/95647?hl=en
      </FlexibleLinkComponent>,
    ],
  },
  {
    id: 'Firefox',
    cells: [
      'Firefox',
      <FlexibleLinkComponent
        key="firefox"
        to="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
      >
        https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
      </FlexibleLinkComponent>,
    ],
  },
  {
    id: 'Safari',
    cells: [
      'Safari',
      <FlexibleLinkComponent
        key="safari"
        to="https://support.apple.com/en-nz/guide/safari/sfri11471/mac"
      >
        https://support.apple.com/en-nz/guide/safari/sfri11471/mac
      </FlexibleLinkComponent>,
    ],
  },
  {
    id: 'IE',
    cells: [
      'Internet Explorer',
      <FlexibleLinkComponent
        key="ie"
        to="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
      >
        https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
      </FlexibleLinkComponent>,
    ],
  },
];

export const cookiePolicyHeaders: Clause[] = [
  headerLink('What are cookies'),
  headerLink('How does Sharesight use cookies'),
  headerLink('Essential cookies'),
  headerLink('Functionality cookies'),
  headerLink('Performance cookies'),
  headerLink('Advertising cookies'),
  headerLink('What happens to data collected by cookies that have been downloaded in the past'),
  headerLink('How do I manage my cookies'),
];

export const cookiePolicyContent: CookiePolicyContent[] = [
  {
    heading: 'What are cookies',
    content: (
      <p>
        Cookies are small text files sent to your browser by a website you visit. They help websites
        to work more efficiently as well as provide site owners with data to enhance your
        experience.
      </p>
    ),
  },
  {
    heading: 'How does Sharesight use cookies',
    content: (
      <>
        <p>
          Sharesight uses cookies alongside tracking pixels to collect data from your device. We use
          this data to customise the user experience, gather analytical data and place
          advertisements. We may disclose personal data we hold about you to a third party for any
          purpose that is directly or indirectly related to the normal use of Sharesight.
        </p>
        <p>
          Sharesight uses essential, functionality, performance and advertising cookies, further
          detailed below. You are in control of your cookies, and can choose to turn all off except
          for essential cookies.
        </p>
      </>
    ),
  },
  {
    heading: 'Essential cookies',
    content: (
      <>
        <p>
          We use essential cookies to make our website work. You can disable these cookies in your
          browser, but parts of sharesight.com may not work.
        </p>
        <br />
        <CookieTable cookieType="essential" />
      </>
    ),
  },
  {
    heading: 'Functionality cookies',
    content: (
      <>
        <p>
          Sharesight uses functionality cookies to improve the user experience by saving your
          preferences in order to show content most relevant to you.
        </p>
        <br />
        <CookieTable cookieType="functionality" />
      </>
    ),
  },
  {
    heading: 'Performance cookies',
    content: (
      <>
        <p>
          Performance cookies are used on sharesight.com if you give us permission to do so. We use
          these cookies to gather anonymous data on how visitors use our website
        </p>
        <br />
        <CookieTable cookieType="performance" />
      </>
    ),
  },
  {
    heading: 'Advertising cookies',
    content: (
      <>
        <p>
          These cookies are placed by our advertising and social media partners, if you give us the
          permission to do so. Our partners may act as intermediaries between us and advertisers,
          they collect the information about your use of our website and other third party websites
          to make online and social advertising more relevant to your interests. We also use these
          cookies to invite specific customer groups to reconnect with Sharesight later.
        </p>
        <br />
        <CookieTable cookieType="advertising" />
        <br />
        <p>
          As mentioned above, information obtained through third party cookies will be disclosed to,
          or collected directly by those third parties. Removing cookies in your browser settings
          means that any preference settings you have made on a website will be lost (including any
          consent preferences regarding cookies where that is available).
        </p>
      </>
    ),
  },
  {
    heading: 'What happens to data collected by cookies that have been downloaded in the past',
    content: (
      <p>
        If you had initially consented to one or more categories, we may still use the data
        collected at that time, when you had previously provided your consent. When you change your
        settings to disable any of the non-essential categories, we will re-set our systems as soon
        as reasonably possible to stop using those categories to gather any further data.
      </p>
    ),
  },
  {
    heading: 'How do I manage my cookies',
    content: (
      <>
        <p>
          You can change the cookie preferences by visiting the cookie settings towards the footer
          of any of the website pages. See below for more information on how to manage your cookie
          settings in these popular browsers:
        </p>
        <br />
        <Table columns={browserColumns} rows={browserCells} />
        <br />
        <p>
          You can also contact us at support@sharesight.com if you wish to withdraw cookie consent
          or update your preferences.
        </p>
      </>
    ),
  },
];
