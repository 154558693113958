import { InnerCell } from './InnerCell.styled';
import type { CookieData, CookieTableRow } from './types';

export const buildCookieRows = (data: CookieData[]): CookieTableRow[] =>
  data.map(cookieData => ({
    id: `${cookieData.cookieName}-${cookieData.creator}`,
    cells: [
      <InnerCell key="cookie-name">
        <p>{cookieData.cookieName}</p>
      </InnerCell>,
      <p key="creator">{cookieData.creator}</p>,
      <p key="purpose">{cookieData.purpose}</p>,
      <p key="expiry">{cookieData.expiry}</p>,
    ],
  }));
